import React from "react"
import { Col, Row } from "react-bootstrap"

/* import MyStyles from "./team.module.css" */
import "./team.css"

import Scheda from "./scheda"

const ListaSchede = ({ lista }) => (
  <>
    <Row>
      {lista.map(({ nodes, fieldValue }, index) => (
        <Col sm={12} md={6} lg={6} xl={4} key={index} className="p-4">
          <div className="h-100 shadow-lg ">
            {/* <div className={`${MyStyles.hr2} m-0`} /> */}
            <h4 className="p-3 border-bottom text-center">
              {fieldValue}
            </h4>
            {/* <div className={`${MyStyles.hr2} m-0`} /> */}
            <Scheda nodo_reparto={nodes} className="shadow my-3 mx-2"/>
          </div>
        </Col>
      ))}
    </Row>
  </>
)

export default ListaSchede
