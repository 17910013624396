import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Col, Row /* , Card */ } from "react-bootstrap"

/* import MyStyles from "./team.module.css" */
import "./team.css"
import ListaSchede from "./listaSchede"

const CdaContent = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/md/schede_personali/" } }
          sort: { order: ASC, fields: frontmatter___sort }
        ) {
          group(field: frontmatter___reparto) {
            totalCount
            fieldValue
            nodes {
              excerpt(format: MARKDOWN)
              frontmatter {
                title
                sort
                email
                path
                reparto
                type
                file_pdf
                presentation
                curriculum
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Container>
        <Row>
            <Col md={1}></Col>
            <Col  className="pt-5">
              <div>
                <div className="display-4 text-center">Team</div>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row>
            <Col md={1}></Col>
            <Col  className="pt-5">
              <div>
                <p className="paragraph2">
                  Archimede s.r.l. dispone di un team esperto e
                  interdisciplinare, che lavora con professionalità nei più
                  svariati ambiti dell'ingegneria. Si compone di ingegneri,
                  architetti, giuristi, esperti linguistici, esperti economici e
                  commercialisti. Ogni area si affida a professionalità che
                  operano in gruppo e con uno spirito di squadra che
                  caratterizza la società.
                </p>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Container>


        <div className="p-3">
        <Container
          fluid
          className="h-100 p-5"
          style={{
            paddingTop: `2rem`,
            paddingBottom: `2rem`,
          }}
        >
          {/* <Row>
            <Col md={1}></Col>
            <Col>
              <div>
                <div className="display-4 text-center">Divisioni :</div>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row> */}
          <Row className="p-3">
            <Col></Col>
            <Col>
              <hr />
            </Col>
            <Col></Col>
          </Row>

          <ListaSchede lista={data.allMarkdownRemark.group}></ListaSchede>
          <Row>
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>

          {/* {data.allMarkdownRemark.edges.map(({ node }, index) => (
            <div key={index}>
              <Row>
                <Col md={1}></Col>
                <Col>
                  <div>
                    <h3>{node.frontmatter.title}</h3>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
                </Col>
                <Col md={1}></Col>
              </Row>
            </div>
          ))} */}

          {/* <div class="col-md-12 text-right">
							<img class="img-fluid w-50 mt-2" src="assets/images/loghi/firma.png" alt="">
						</div> */}
        </Container>
        </div>
      </>
    )}
  />
)
export default CdaContent
