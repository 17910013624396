import React from "react"
/* import { MdEmail } from "react-icons/md"  ------------------------ */

/* import { Container, Col, Row , Card } from "react-bootstrap" */
import Curriculum from "./curriculum"

/* import MyStyles from "./team.module.css" */
/* import "./team.css" */

import ModalPresentation from "./modalPresentation"

const Scheda = ({ nodo_reparto, className }) => {
  return (
    <div className="mx-5 my-4">
      {nodo_reparto.map(({ frontmatter, excerpt }, index) => (
        <div key={index} className={className}>
          <div className="text-left p-4">
            <ModalPresentation frontmatter={frontmatter} html={excerpt} />

            {!frontmatter.curriculum ? (
              ``
            ) : (
              <Curriculum
                title="Curriculum"
                name={frontmatter.file_pdf}
                valore_alternativo={frontmatter.path}
              />
            )}

            {/* <h5>
              {!frontmatter.presentation ? (
                <a className={MyStyles.link_no_style}>{frontmatter.title}</a>
              ) : (
                <a href={frontmatter.path} className={MyStyles.link_no_style}>
                  {frontmatter.title}
                </a>
              )}
              <a href="#" className="link-no-style">{frontmatter.title}</a>
              <br />
              <small className="text-muted">{frontmatter.type}</small>
            </h5> */}
            {/* <p><MdEmail/> {frontmatter.email}</p> ------------------------ */}

            {/* <a class="font-weight-light" target="_blank" href={frontmatter.curriculum}>CURRICULUM</a> */}
            {/* <Row>
              <Col className="col-4"></Col>
              <Col>
                <div className={`${MyStyles.hr2}`} />
              </Col>
              <Col className="col-4"></Col>
            </Row> */}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Scheda

/* 

const Scheda = ({ nodo_reparto }) => {
  return (
    
    <div className="m-3">
      {nodo_reparto.map(({ frontmatter }, index) => (
        <div key={index} className="text-center">
          <h5>{frontmatter.title}</h5>
          <p>
            {frontmatter.type} <br />
            E-mail: {frontmatter.email}
          </p>
          <a class="font-weight-light" target="_blank" href={frontmatter.curriculum}>CURRICULUM</a>
          <Row>
            <Col className="col-4"></Col>
            <Col>
              <div className={`${MyStyles.hr2}`} />
            </Col>
            <Col className="col-4"></Col>
          </Row>
        </div>
      ))}
    </div>
  )
}

*/
