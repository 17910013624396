import React from "react"

import ModalObject from "../../components/contents/modalObject"

import MyStyles from "./team.module.css"

const ContentPresentation = ({ frontmatter, html }) => (
  <>
    <h5>
      <div className="mb-2">
        {!frontmatter.presentation ? (
          <div className={`${MyStyles.link_style_a} d-inline-flex`}>{frontmatter.title}</div>
        ) : (
          <ModalObject
            childLink={
              <div className={`${MyStyles.link_style_b} d-inline-flex`}>{frontmatter.title}</div>
            }
            modalTitle={frontmatter.title}
            className=""
          >
            <div className="p-4">
              {/* <h5>
              {frontmatter.title} <br />
              <small className="text-muted">{frontmatter.type}</small>
            </h5> */}
              <div className="" dangerouslySetInnerHTML={{ __html: html }} />
            </div>

            {/* <Link to={`/${frontmatter.path}`} className="link-no-style">
              CONTINUA
            </Link> */}
          </ModalObject>
        )}
      </div>
      {/* <a href="#" className="link-no-style">{frontmatter.title}</a> */}
      {/* <br /> */}
      <div className="pl-2">
        <small className="text-muted">{frontmatter.type}</small>
      </div>
    </h5>
  </>
)
export default ContentPresentation
