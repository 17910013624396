import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layouts/layout"
import SEO from "../../components/seo"
import Header4 from "../../components/contents/header4"

import CdaContent from "../../content/cda/cda_content"
import TeamContent from "../../content/team/team_content1"

const HorizonPage = ({ data, location }) => (
  /* console.log(data.site, data.site.siteMetadata.cda), */
//   <Layout
//     header={
//       <Header4
//         src_sfondo={data.file.childImageSharp.fluid}
//         title={data.site.siteMetadata.cda.title}
//         link_page={data.site.siteMetadata.cda.link_page}
//       />
//     }
//     location={location}
//   >
//     <SEO
//       title={data.site.siteMetadata.cda.title}
//       pathname={data.site.siteMetadata.cda.link_page}
//     />

//     <CdaContent></CdaContent>
//   </Layout>


<Layout
    location={location}
    header={
      <Header4
        src_sfondo={data.file.childImageSharp.fluid}
        // title= {data.site.siteMetadata.team.title}
        title= "CDA - TEAM"
        link_page={data.site.siteMetadata.team.link_page}
      />
    }
  >
    <SEO
      title={data.site.siteMetadata.team.title}
      pathname={data.site.siteMetadata.team.link_page}
    />
  
    <div id="team1"> <CdaContent /> </div>

    <div> <TeamContent /> </div>
  </Layout>
    
)

export const query = graphql`
  {
    file(relativePath: { regex: "/team/header/" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        team {
          title
          seo_title
          link_page
        }
      }
    }
  }
`

export default HorizonPage
